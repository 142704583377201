'use strict';

angular.module('myApp.services', ['ngRoute'])

.controller('ServicesCtrl', ['$scope', '$timeout', '$stateParams', '$http', '$interval', '$rootScope', function($scope, $timeout, $stateParams, $http, $interval, $rootScope) {
	$scope.dataLogo    = 'assets/images/logo-black.png';
  $scope.itemActive  = 1;
  $scope.projectType = $stateParams.type;
  $http.get('layout/detail-services/data.json')
    .then(function(res){
        $scope.data = res.data[$stateParams.type];

        $rootScope.meta.title       = $scope.data.meta.title;
        $rootScope.meta.description = $scope.data.meta.description;

    },function(error){
        console.log(error);
    });

  angular.element(document).ready(function () {
      angular.element('.nice-select').niceSelect();
  });
  angular.element(document).ready(function () {
      $('.our-works-list').slick({
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
          arrows: false,
      });
  });

  $scope.autoplay = function(play){
      if(play){
          $scope.auto = $interval(function () {
              if($scope.itemActive >= 7){
                  $scope.itemActive = 1;
              }else{
                  $scope.itemActive += 1;
              }
              // $scope.animateSVG();
          }, 5000);
      }else{
          $interval.cancel($scope.auto);
          $scope.auto = null;
      }
  }

  // $scope.animateSVG = function(){
  //     var loop = "M20,2c9.9,0,18,8.1,18,18s-8.1,18-18,18S2,29.9,2,20S10.1,2,20,2";
  //     var svgID = '#circle-'+ $scope.itemActive;
  //     angular.element(svgID).html("");
  //     var loopLength = Snap.path.getTotalLength(loop);
  //     var a = new Snap(svgID);

  //     var circle = a.path({
  //         path: loop,
  //         fill: "none",
  //         stroke: "#FFA600",
  //         strokeWidth: 2,
  //         strokeLinecap: "round"
  //     });

  //     var circleOutline =  a.path({
  //         path: Snap.path.getSubpath(loop, 0, 0),
  //         fill:"none",
  //         stroke: "#fff",
  //         fillOpacity: 0,
  //         strokeWidth: 4,
  //         strokeLinecap: "round"
  //     });
  //     $timeout(function(){
  //         Snap.animate(0, loopLength,
  //             function(step){ //step function
  //                 circleOutline.attr({
  //                     path: Snap.path.getSubpath(loop, 0, step),
  //                     strokeWidth: 4
  //                 });
  //             }, 5000, mina.easeInOut
  //         );
  //     },500);
  // }

  $scope.sequence = function(first){
      if(first){
          // $scope.animateSVG();
          $timeout(function(){
              $scope.autoplay(true);
          },100);
      }else{
          $scope.itemActive += 1;
          $scope.autoplay(true);
      }
  }

  $scope.activeChange = function(e){
      $scope.itemActive = e;
      // $scope.animateSVG();
      $scope.autoplay(false);
      $timeout(function(){
        $scope.sequence(false);
      },5000);
  }

  $timeout(function(){
      $scope.sequence(true);
  },1000);

	$scope.pagination = {
      onepage : [
          {
              classes_targer: 'section-1',
              active_state: 'active'
          },
          {
              classes_targer: 'section-2',
          },
          {
              classes_targer: 'section-3',
          },
          {
              classes_targer: 'section-4',
          },
          {
              classes_targer: 'section-5',
          },
          {
              classes_targer: 'section-6',
          },
          {
              classes_targer: 'section-7',
          },
          {
              classes_targer: 'section-footer',
          }
      ]
  };

  // $scope.tempData = [
  //   {
  //     "title": "Technically Skilled Project Manager",
  //     "desc": "All managers in charge of your projects have coding expertise so they know the ins and outs of the WordPress platform.",
  //     "icon": "assets/images/icons/services/1.svg"
  //   },
  //   {
  //     "title": "Unbeatable Price!",
  //     "desc": "Unbeatable Price!",
  //     "icon": "assets/images/icons/services/2.svg"
  //   },
  //   {
  //     "title": "Fast Load Time",
  //     "desc": "Fast Load Time",
  //     "icon": "assets/images/icons/services/3.svg"
  //   },
  //   {
  //     "title": "Intuitive Admin Panels",
  //     "desc": "Intuitive Admin Panels",
  //     "icon": "assets/images/icons/services/4.svg"
  //   },
  //   {
  //     "title": "Supports & Maintanance",
  //     "desc": "Supports & Maintanance",
  //     "icon": "assets/images/icons/services/5.svg"
  //   },
  //   {
  //     "title": "Fast Turn-arrounds",
  //     "desc": "Fast Turn-arrounds",
  //     "icon": "assets/images/icons/services/6.svg"
  //   },
  //   {
  //     "title": "Non-Disclosure Agreement",
  //     "desc": "Non-Disclosure Agreement",
  //     "icon": "assets/images/icons/services/7.svg"
  //   },
  //   {
  //     "title": "Technically Skilled Project Manager",
  //     "desc": "Technically Skilled Project Manager",
  //     "icon": "assets/images/icons/services/1.svg",
  //     "type": "clone"
  //   },
  //   {
  //     "title": "Unbeatable Price!",
  //     "desc": "Unbeatable Price!",
  //     "icon": "assets/images/icons/services/2.svg",
  //     "type": "clone"
  //   },
  //   {
  //     "title": "Fast Load Time",
  //     "desc": "Fast Load Time",
  //     "icon": "assets/images/icons/services/3.svg",
  //     "type": "clone"
  //   },
  //   {
  //     "title": "Intuitive Admin Panels",
  //     "desc": "Intuitive Admin Panels",
  //     "icon": "assets/images/icons/services/4.svg",
  //     "type": "clone"
  //   },
  //   {
  //     "title": "Supports & Maintanance",
  //     "desc": "Supports & Maintanance",
  //     "icon": "assets/images/icons/services/5.svg",
  //     "type": "clone"
  //   }
  // ];
}])

.controller('ListServicesCtrl', ['$scope', '$timeout', '$location',  function($scope, $timeout, $location) {
    $scope.isActive = function (viewLocation) {
        var active = (viewLocation === $location.path());
        return active;
    };
}])

.controller('StartProjectCtrl', ['$scope', '$timeout', '$location', '$state', '$stateParams', '$window', 'kodekuService',  function($scope, $timeout, $location, $state, $stateParams, $window, kodekuService) {
    $scope.isActive = function (viewLocation) {
        var active = (viewLocation === $location.path());
        return active;
    };
    angular.element(document).ready(function () {
  		angular.element('.nice-select').niceSelect();
  	});

    $scope.emailValidationFooter = function (emailAddress) {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    };

    $scope.project = {};

    $scope.submitGetStarted = function(event){
      var errorCount = [];

      var progress_loading = '<div class="progress_circle">'+
                                '<svg class="circular" viewBox="25 25 50 50">'+
                                  '<circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="6" stroke-miterlimit="10"></circle>'+
                                '</svg>'+
                              '</div>';

      if($scope.project.user_name == "" || $scope.project.user_name == undefined){
          errorCount.push('error name');
          angular.element('.get-quote-form .getname').addClass('has-error').attr('placeholder', 'Sorry, name is empty');
      }else{ angular.element('.get-quote-form .getname').removeClass('has-error').attr('placeholder', 'Your Name*'); }

      if($scope.project.user_email == "" || $scope.project.user_email == undefined){
          errorCount.push('error email');
          angular.element('.get-quote-form .getemail').addClass('has-error').attr('placeholder', 'Sorry, email is empty');
      }else if($scope.emailValidationFooter($scope.project.user_email) != true){
          errorCount.push('error email');
          angular.element('.get-quote-form .getemail').addClass('has-error').attr('placeholder', 'Email address not valid');
      }else{angular.element('.get-quote-form .getemail').removeClass('has-error').attr('placeholder', 'Email Address*');}

      if($scope.project.phone_number == "" || $scope.project.phone_number == undefined){
          errorCount.push('error phone');
          angular.element('.get-quote-form .getphone').addClass('has-error').attr('placeholder', 'Sorry, phone number is empty');
      }else{ angular.element('.get-quote-form .getphone').removeClass('has-error').attr('placeholder', 'Phone Number*'); }


      if($scope.project.project_description == "" || $scope.project.project_description == undefined){
          errorCount.push('error desc');
          angular.element('.get-quote-form .getdesc').addClass('has-error').attr('placeholder', 'Sorry, description is empty');
      }else{angular.element('.get-quote-form .getdesc').removeClass('has-error').attr('placeholder', 'Your Instruction');}

      if(errorCount.length == 0){
        angular.element('.get-quote-form').find('.btn-black.with-arrow').html(progress_loading);
        kodekuService.saveProjectDetail({
            user_name           : $scope.project.user_name,
            user_email          : $scope.project.user_email,
            phone_number        : $scope.project.phone_number,
            project_type        : $stateParams.type,
            project_description : $scope.project.project_description,
        }).then(function(data){
            if(data.status)
            {
                var success = {user_name : $scope.project.user_name, user_email : $scope.project.user_email};
                $window.localStorage['project_success'] = JSON.stringify(success);
                $timeout(function(){
                  angular.element('.get-quote-form').find('.btn-black.with-arrow').html("Submit");
                  $state.go('quote-sent');
                }, 1000);
            }
        });


        event.preventDefault();
        event.stopPropagation();
      }

    }
}])

.directive('roundup', function ($timeout,$interval) {
    return {
      restrict: 'E',
      templateUrl: 'layout/detail-services/roundup.template.html',
      scope: {
        lists: "=",
      },
      link: function (scope, elm, attrs) {
        scope.isActive = 0;
        var parents = elm[0].children[1].children[0].children[0],
            elems = parents.children;

        var nbOptions = 8,
            angleStart = -360;
        var auto = "";

        scope.rotate = function(li, d) {
          $({d: angleStart}).animate({d: d}, {
            step: function (now) {
              $(li).css({
                transform: 'rotate(' + now + 'deg)'
              });
            },
            duration: 0
          });
        }

        scope.setRoundHeight = function(){
          var roundSelector = $(".roundup");
          roundSelector.each(function () {
            var roundHeight = $(this).width();
            $(this).css("height", roundHeight + "px");
          });
        }

        scope.toggleOptions = function(s) {
          $(s).toggleClass('open');
          var li = $(s).find('li');
          var deg = 360 / li.length;
          for(var i=0; i<li.length; i++) {
              var d = i * deg;
              $(s) ? scope.rotate(li[i],d) : scope.rotate(li[i], angleStart);
          }
        }

        scope.switchContent = function(){
          var obj = $(".roundup li a");
          obj.on("click", function (e) {
            var activeClass = "active",
              numItems = $(".roundup li a").length,
              rotationIncrement = 360 / numItems,
              clickedIndex = $(this).parent().index(),
              previousActiveIndex = $(".roundup li.active a").parent().index(),
              previousRotation = previousActiveIndex * rotationIncrement,
              newRotation = clickedIndex * rotationIncrement,
              distance = newRotation - previousRotation,

              imgactive = $('.roundup li a.active').find('img').data("active"),
              img = $('.roundup li a.active').find('img').attr('src'),
              active = $(this).find('img').data('active');

            $(".roundup").css({
              transform: 'rotateZ(-' + (clickedIndex * rotationIncrement) + 'deg)'
            });

            e.preventDefault();

            if ($(this).hasClass('active')) {
              return false;
            }

            obj.removeClass(activeClass);
            $(this).toggleClass(activeClass);

            if(clickedIndex >= 7){
              $timeout(function() {
                scope.isActive = clickedIndex - 7;
              });
            }else{
              $timeout(function() {
                scope.isActive = clickedIndex;
              });
            }

            $('.item-slider').children().css('opacity','0');

            // scope.$apply();

          });
        }

        $timeout(function(){
          scope.setRoundHeight();
          scope.toggleOptions('.roundup');
          scope.switchContent();

          auto = $interval(function () {
            $(".roundup li a.active").parent().next().find('a').click();
          }, 5000);

          $(document).on('click', '.roundup-act', function (e) {
            e.preventDefault();
            if ($(this).parent().hasClass('active')) {
              return false;
            }
            $('.roundup-act').parent().removeClass('active');
            $(this).parent().addClass('active');
            var target = $(".roundup li").eq($(this).data('index'));
            $(target).find('a').click();
            $interval.cancel(auto);
            $timeout(function () {
              auto = $interval(function () {
                $(".roundup li a.active").parent().next().find('a').click();
              }, 5000);
            }, 10000);
          });
        }, 200);

      },
      controller: function(){

      }
    }
  })

  .directive('changeAnimate',function($timeout, $animate){
    return {
        restrict: 'A',
        scope: {
          changeAnimate: '@'
        },
        link: function(scope, elem, attrs){
           scope.$watch(
           function(){
             return attrs.index;
           },
           function(){
             $animate.removeClass(elem, scope.changeAnimate);
             $timeout(function(){
              elem.css('opacity','1');
              $animate.addClass(elem, scope.changeAnimate);
             },10);
           },true);
        }
    };
  })
