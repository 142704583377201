'use strict';

angular.module('myApp.howit', ['ngRoute'])

.controller('HowitCtrl', ['$scope', '$timeout', '$interval', '$rootScope', function($scope, $timeout, $interval, $rootScope) {
	$scope.dataLogo = 'assets/images/logo-white.svg';
  $scope.headermenu = 'in-gradient';
  $scope.itemActive = 1;

  $scope.autoplay = function(play){
      if(play){
          $scope.auto = $interval(function () {
              if($scope.itemActive >= 7){
                  $scope.itemActive = 1;
              }else{
                  $scope.itemActive += 1;
              }
              // $scope.animateSVG();
          }, 5000);
      }else{
          $interval.cancel($scope.auto);
          $scope.auto = null;
      }
  }

  // $scope.animateSVG = function(){
  //     var loop = "M20,2c9.9,0,18,8.1,18,18s-8.1,18-18,18S2,29.9,2,20S10.1,2,20,2";
  //     var svgID = '#circle-'+ $scope.itemActive;
  //     angular.element(svgID).html("");
  //     var loopLength = Snap.path.getTotalLength(loop);
  //     var a = new Snap(svgID);

  //     var circle = a.path({
  //         path: loop,
  //         fill: "none",
  //         stroke: "#FFA600",
  //         strokeWidth: 2,
  //         strokeLinecap: "round"
  //     });

  //     var circleOutline =  a.path({
  //         path: Snap.path.getSubpath(loop, 0, 0),
  //         fill:"none",
  //         stroke: "#fff",
  //         fillOpacity: 0,
  //         strokeWidth: 4,
  //         strokeLinecap: "round"
  //     });
  //     $timeout(function(){
  //         Snap.animate(0, loopLength,
  //             function(step){ //step function
  //                 circleOutline.attr({
  //                     path: Snap.path.getSubpath(loop, 0, step),
  //                     strokeWidth: 4
  //                 });
  //             }, 5000, mina.easeInOut
  //         );
  //     },500);
  // }

  $scope.sequence = function(first){
      if(first){
          // $scope.animateSVG();
          $timeout(function(){
              $scope.autoplay(true);
          },100);
      }else{
          $scope.itemActive += 1;
          $scope.autoplay(true);
      }
  }

  $scope.activeChange = function(e){
      $scope.itemActive = e;
      // $scope.animateSVG();
      $scope.autoplay(false);
      $timeout(function(){
        $scope.sequence(false);
      },5000);
  }

  $timeout(function(){
      $scope.sequence(true);
  },1000);

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            },
        ]
    };
}]);
