'use strict';

angular.module('myApp.story', ['ngRoute', 'myApp.storyEmail'])

.controller('DevstoryCtrl', ['$scope', '$timeout', '$location', function($scope, $timeout, $location) {
	$scope.dataLogo = 'assets/images/logo-white.svg';
	$scope.headermenu = 'in-gradient';

    $scope.isActive = function (viewLocation) {
        var active = (viewLocation === $location.path());
        return active;
    };

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            }
        ]
    };
}]);