'use strict';

angular.module('myApp.agency', ['ngRoute'])

.controller('AgencyCtrl', ['$scope', '$timeout', '$location', function($scope, $timeout, $location) {
	$scope.dataLogo = 'assets/images/logo-black.png';
	$scope.isActive = function (viewLocation) {
		var active = (viewLocation === $location.path());
		return active;
	};
    $scope.animateIn = function($el) {
      $el.addClass('inview');
    };
	angular.element(document).ready(function () {
		angular.element('.nice-select').niceSelect();
        angular.element('.agency .img-hero').addClass('inslide');
	});

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-2',
            },
            {
                classes_targer: 'section-3',
            },
            {
                classes_targer: 'section-4',
            },
            {
                classes_targer: 'section-footer',
            },
        ]
    };
}]);
