'use strict';

angular.module('myApp.faq', ['ngRoute'])

.controller('FaqCtrl', ['$scope', '$timeout', function($scope, $timeout) {
	$scope.dataLogo = 'assets/images/logo-white.svg';
	$scope.headermenu = 'in-gradient';

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            }
        ]
    };
    angular.element(document).ready(function () {
        $('.menu-side ul').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false,
            centerMode: true,
        });

        $('.menu-side ul li').click(function(e){
            e.preventDefault();
            var slideIndex = $(this).attr("data-slick-index");
            $('.menu-side ul').slick('slickGoTo', slideIndex);
            $('.content-tabs').find('.items-content').removeClass('open');
            $('.content-tabs').find('.items-content#'+slideIndex).addClass('open');
        });
        $('.menu-side ul').on('afterChange', function(event, slick, currentSlide, nextSlide){
            $('.content-tabs').find('.items-content').removeClass('open');
            $('.content-tabs').find('.items-content#'+currentSlide).addClass('open'); 
        });
    });
}]);