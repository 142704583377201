'use strict';

angular.module('myApp.ourworks', ['ngRoute'])

.controller('OurworksCtrl', ['$scope', '$timeout', '$location', function($scope, $timeout, $location) {
	$scope.dataLogo = 'assets/images/logo-white.svg';
	$scope.headermenu = 'in-gradient';
	$scope.isActive = function (viewLocation) {
		var active = (viewLocation === $location.path());
		return active;
	};

    angular.element(document).ready(function () {
        // $('.our-works-list').slick({
        //     infinite: false,
        //     slidesToShow: 2,
        //     slidesToScroll: 1,
        //     variableWidth: true,
        //     arrows: false,
        // });
    });

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            },
        ]
    };
    $scope.animateIn = function($el) {
      $el.addClass('inview');
    };
}])

.controller('DetailourworksCtrl', ['$scope', '$timeout', '$location', '$stateParams', '$http', '$sce', '$rootScope', function($scope, $timeout, $location, $stateParams, $http, $sce, $rootScope) {
    $http.get('layout/our-works/data.json')
       .then(function(res){
          $scope.data = res.data[$stateParams.type];

          $rootScope.meta.title         = $scope.data.meta.title;
          $rootScope.meta.description   = $scope.data.meta.description;

        },function(error){
          console.log(error);
        });
	$scope.dataLogo = 'assets/images/logo-white.svg';
	$scope.headermenu = 'in-gradient';
	$scope.isActive = function (viewLocation) {
		var active = (viewLocation === $location.path());
		return active;
    };
    
    $scope.renderHtml = function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
    };

    $scope.animateIn = function($el) {
      $el.addClass('inview');
    };

    $scope.visitProject = function() {
        window.open($scope.data.siteurl, '_blank');
    };

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-2',
            },
            {
                classes_targer: 'section-footer',
            },
        ]
    };

    angular.element(document).ready(function() {
        var $fixedHero = $('#img-work');

        $fixedHero.css({
            backgroundPosition: '50%  67px'
        });

        $(window).scroll(function() {
            var wScroll = $(this).scrollTop();
            var headScroll = (-wScroll / 2);
            var scrollbgplus = 67 + headScroll;

            if(scrollbgplus >= 0){
                $fixedHero.css({
                    backgroundPosition: '50%' + (scrollbgplus) + 'px'
                });
            }

        });
    });
}])
