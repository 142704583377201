'use strict';

angular.module('myApp.factory', [])

.factory('kodekuService', ['$q','$http', function($q, $http){
    var headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8;'};
    var apiUrl  = window.globalVariable.apiUrl;

    return ({
        subscribe   : subscribe,
        saveProject : saveProject,
        saveContact : saveContact,
        saveProjectDetail : saveProjectDetail
    });


    function subscribe(data) 
    {
        var request = $http.post( apiUrl + "subscribe/save", data, { headers : headers });
        return (request.then(handleSuccess, handleError));
    }

    function saveProject(data)
    {
        var request = $http.post( apiUrl + "project/save", data, { headers: headers });
        return (request.then(handleSuccess, handleError) );
    }

    function saveProjectDetail(data)
    {
        var request = $http.post( apiUrl + "project/saveDetail", data, { headers: headers });
        return (request.then(handleSuccess, handleError) );
    }
    
    function saveContact(data) 
    {
        var request = $http.post( apiUrl + "contact/save", data, { headers : headers });
        return (request.then(handleSuccess, handleError));
    }

    function handleError( response ) 
    {
        if (! angular.isObject( response.data ) || ! response.data.message) 
        {
            return( $q.reject( "An unknown error occurred." ) );
        }
        return( $q.reject( response.data.message ) );
    }

    function handleSuccess( response ) 
    { 
        return( response.data );
    }


}]);