'use strict';

angular.module('myApp.quotes', ['ngRoute'])

.controller('QuotesCtrl', ['$scope', '$timeout', 'kodekuService', '$window', '$state', function($scope, $timeout, kodekuService, $window, $state) {
	$scope.dataLogo    = 'assets/images/logo-white.svg';
	$scope.headermenu  = 'in-gradient';
    $scope.project     = {};
    $scope.projectUrl  = [];
    $scope.projectName = [];
    $scope.projectType = [];

    if( $window.localStorage['started'] )
    {
        var started = JSON.parse( $window.localStorage['started'] );
        $scope.project.user_name    = started.user_name;
        $scope.project.user_email   = started.user_email;
        $scope.project.phone_number = started.phone_number;
    }

    if( $window.localStorage['step1'] )
    {
        var step1 = JSON.parse( $window.localStorage['step1'] );
        $scope.project.project_name     = step1.project_name;
        $scope.projectType              = step1.project_type;
        $('.box-options li').each(function(e){
            var val = $(this).data('name');
            var idx = $scope.projectType.indexOf(val)
            if( idx > -1 )
            {
                $(this).addClass('active');
            }
        });

    }

    if( $window.localStorage['projectName'] && $window.localStorage['projectUrl'] )
    {
        var fileUrl     = JSON.parse( $window.localStorage['projectUrl'] );
        var fileName    = JSON.parse( $window.localStorage['projectName'] );
        angular.forEach(fileUrl, function(val, key) {
          $scope.projectUrl.push(val);
        });

        angular.forEach(fileName, function(val, key) {
          $scope.projectName.push(val);
          $('<p/>').text(val).appendTo('.append_files');
        });
    }

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            },
        ]
    };

    angular.element(document).ready(function () {
        $('.box-options').slick({
            infinite: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false,
        });

        $('.area-drag .progress_circle').hide();

        angular.element('.box-options li').click(function(){
            // $scope.project.project_type = angular.element(this).attr('data-name');
            //angular.element('.box-options li').removeClass('active');
            angular.element(this).toggleClass('active');

            var val = angular.element(this).attr('data-name');
            var idx = $scope.projectType.indexOf(val)
            
            if (idx > -1) 
            {
                $scope.projectType.splice(idx, 1);
            }
            else {
                $scope.projectType.push(val);
            }

        });
    });

    var options = {
        success: function(files) {
            for (var i = 0; i < files.length; i++){
                $('<p/>').text(files[i].name).appendTo('.append_files');
                $scope.projectUrl.push(files[i].link);  
                $scope.projectName.push(files[i].name);

                $window.localStorage['projectUrl']  = JSON.stringify($scope.projectUrl);
                $window.localStorage['projectName'] = JSON.stringify($scope.projectName);
            }
        },
        cancel: function() {
        },
        linkType: "preview",
        multiselect: true,
    };

    angular.element('.btn-dropbox').click(function(){
        Dropbox.choose(options);
    });

    $('.area-drag').click(function(){
        $('#fileupload').click();
    });

    $('#fileupload').fileupload({
        url         : window.globalVariable.fileUploadUrl,
        dataType    : 'json',
        add         : function (e, data) {
            // if(data.originalFiles[0]['size'] > 2560000) 
            if(data.originalFiles[0]['size'] > 10000000) 
            {
                $('.area-drag p').text('Filesize to Big');
            }else
            {
                $('.area-drag p').text('Uploading...');
                $('.area-drag').addClass('uploading');
                $('.area-drag .progress_circle').show();
                data.submit();
            }
        },
        done: function (e, data) {
            $('.area-drag p').text('Browse your file here');
            $('.area-drag').removeClass('uploading');
            $('.area-drag .progress_circle').hide();
            $.each(data.result.files, function (index, file) {
                $('<p/>').text(file.name).appendTo('.append_files');
                $scope.projectUrl.push(file.url);     
                $scope.projectName.push(file.name);

                var heightVal = $('.get-startedHeight').height() + $('.append_files p').last().height() + 200;

                $('.get-startedHeight').css({
                    'height': heightVal
                })

                $window.localStorage['projectUrl']  = JSON.stringify($scope.projectUrl);
                $window.localStorage['projectName'] = JSON.stringify($scope.projectName);

            });
        }
    });

    $scope.emailValidation = function (emailAddress) {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    };

    $scope.getStarted = function(event){
        var errorCount = [];
        
        if($scope.project.user_name == "" || $scope.project.user_name == undefined){
            errorCount.push('error name');
            angular.element('.step-form .quotes_names').addClass('has-error').attr('placeholder', 'Sorry, name is empty');
        }else{ angular.element('.step-form .quotes_names').removeClass('has-error').attr('placeholder', 'First name'); }

        if($scope.project.user_email == "" || $scope.project.user_email == undefined){
            errorCount.push('error email');
            angular.element('.step-form .quotes_emails').addClass('has-error').attr('placeholder', 'Sorry, email is empty');
        }else if($scope.emailValidation($scope.project.user_email) != true){
            errorCount.push('error email');
            angular.element('.step-form .quotes_emails').addClass('has-error').val('').attr('placeholder', 'Sorry, email not valid');
        }else{angular.element('.step-form .quotes_emails').removeClass('has-error').attr('placeholder', 'name@company.com');}

        if($scope.project.phone_number == "" || $scope.project.phone_number == undefined){
            errorCount.push('error phone');
            angular.element('.step-form .quotes_phone').addClass('has-error').attr('placeholder', 'Sorry, phone is empty');
        }else{angular.element('.step-form .quotes_phone').removeClass('has-error').attr('placeholder', '');}

        if(errorCount.length == 0){
            var started = {
                        'user_name'     : $scope.project.user_name,
                        'user_email'    : $scope.project.user_email,
                        'phone_number'  : $scope.project.phone_number,
                    };
            $window.localStorage['started'] = JSON.stringify(started);

            var currentPos = angular.element('.step-form[data-section="form1"]');
                currentPos.removeClass('active').addClass('done');
                currentPos.next().addClass('active').removeClass('done hided');

            event.preventDefault();
            event.stopPropagation();
        }
    };
    
    $scope.getStepOne = function(event){
        var errorCount = [];
        
        if($scope.project.project_name == "" || $scope.project.project_name == undefined){
            errorCount.push('error project name');
            angular.element('.step-form .quotes_project').addClass('has-error').attr('placeholder', 'Sorry, project name is empty');
        }else{ angular.element('.step-form .quotes_project').removeClass('has-error').attr('placeholder', 'Awesome project'); }

        if($scope.projectType.length == 0){
            errorCount.push('error project');
            angular.element('.step-form .notice_list').show();
        }else{ angular.element('.step-form .notice_list').hide(); }

        if(errorCount.length == 0){
            var step1 = {
                        'project_name'  : $scope.project.project_name,
                        'project_type'  : $scope.projectType
                    };
            $window.localStorage['step1'] = JSON.stringify(step1);

            var currentPos = angular.element('.step-form[data-section="form2"]');
                currentPos.removeClass('active').addClass('done');
                currentPos.next().addClass('active').removeClass('done hided');

            var heightVal = $('.get-startedHeight').height() + $('.append_files').height() + 150;

            $('.get-startedHeight').css({
                'height': heightVal
            });

            event.preventDefault();
            event.stopPropagation();
        }
    };

    $scope.getStepTwo = function(event){
        var errorCount = [];
        
        if($scope.project.project_description == "" || $scope.project.project_description == undefined){
            errorCount.push('error project_description');
            angular.element('.step-form .quotes_description').addClass('has-error').attr('placeholder', 'Sorry, instruction is empty');
        }else{ angular.element('.step-form .quotes_description').removeClass('has-error').attr('placeholder', 'Your Instruction'); }

        if(errorCount.length == 0){
            kodekuService.saveProject({
                user_name           : $scope.project.user_name,
                user_email          : $scope.project.user_email,
                phone_number        : $scope.project.phone_number,
                project_name        : $scope.project.project_name,
                project_type        : $scope.projectType,
                project_description : $scope.project.project_description,
                url                 : $scope.projectUrl
            }).then(function(data){
                if(data.status)
                { 
                    var success = {user_name : $scope.project.user_name, user_email : $scope.project.user_email};
                    $window.localStorage['project_success'] = JSON.stringify(success);

                    $window.localStorage.removeItem('started');
                    $window.localStorage.removeItem('step1');
                    $window.localStorage.removeItem('projectUrl');
                    $window.localStorage.removeItem('projectName');
                
                    $state.go('quote-sent');
                }
            });

            var currentPos = angular.element('.step-form[data-section="form3"]');
                currentPos.removeClass('active').addClass('done');
                currentPos.next().addClass('active').removeClass('done hided');

            event.preventDefault();
            event.stopPropagation();
        }
    };

    $('#next1').click(function(e){
        $scope.getStepOne(e);
    })

}])

.controller('QuoteSendCtrl', ['$scope', '$timeout', '$window', '$state', function($scope, $timeout, $window, $state) {
    $scope.dataLogo    = 'assets/images/logo-white.svg';
    $scope.headermenu  = 'in-gradient';

    $scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            },
        ]
    };

    if( ! $window.localStorage['project_success']  )
    {
        //$state.go('get-started');
    }

    $scope.success = {};
    $scope.$on('$viewContentLoaded', function(){
        if( $window.localStorage['project_success'] )
        {
            var success = JSON.parse( $window.localStorage['project_success'] );
            $scope.success.user_name    = success.user_name.toUpperCase();
            $scope.success.user_email   = success.user_email;
            $timeout(function () {
                 $window.localStorage.removeItem('project_success'); 
            },3000);
        }
    });

}]);