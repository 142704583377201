'use strict';

// Declare app level module which depends on views, and components
window.globalVariable = {
  apiUrl            : "https://api.kodeku.net/",
  fileUploadUrl     : "https://api.kodeku.net/fileupload/"
};

angular.module('myApp', [
  'ngRoute',
  'ui.router',
  'ngAnimate',
  'myApp.contact',
  'myApp.services',
  'myApp.service',
  'myApp.story',
  'myApp.faq',
  'myApp.agency',
  'myApp.quotes',
  'myApp.home',
  'myApp.howit',
  'myApp.ourworks',
  'myApp.policy',
  'myApp.pricing',
  'myApp.sitemap',
  'myApp.factory',
  'myApp.errors',
  'angular-scroll-animate'
])

.config(['$stateProvider', '$urlRouterProvider', '$locationProvider', function($stateProvider, $urlRouterProvider, $locationProvider) {
    $urlRouterProvider.otherwise('/error404');
    $stateProvider
        .state('home', {
            url: '/',
            templateUrl: 'layout/home/index.html',
            controller: 'HomeCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | Web and App Development Agency';
                        $rootScope.meta.description     = 'We producing refined Website, Mobile Application and UI/UX solutions with a broad knowledge and experience of building themes and plugins for various CMSes including Wordpress, Woocommerce & Magento.';
                    });
                }]
            }
        })
        .state('our-works', {
            url: '/our-works',
            templateUrl: 'layout/our-works/our-works.html',
            controller: 'OurworksCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | Our Masterpiece Projects';
                        $rootScope.meta.description     = 'At KODEKU, we let our work speaks for itself. Take a look at some of our projects for insights into our affordable design and development services.';
                    });
                }]
            }
        })
        .state('detail-our-works', {
            url: '/detail-our-works/:type',
            templateUrl: 'layout/our-works/detail-works.html',
            controller: 'DetailourworksCtrl'
        })
        .state('agency', {
            url: '/agency',
            templateUrl: 'layout/for-agency/agency.html',
            controller: 'AgencyCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | For Agencies. We Skyrocket your Company';
                        $rootScope.meta.description     = 'We Skyrocket your Company. Share your workload with us so you can focus on what truly matters. KODEKU will boost your productivity. Discover our flexible and affordable solution for your development needs.';
                    });
                }]
            }
        })
        .state('contact', {
            url: '/contact',
            templateUrl: 'layout/contact/contact.html',
            controller: 'CotactCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | For Agencies. We Skyrocket your Company';
                        $rootScope.meta.description     = '';
                    });
                }]
            }
        })
        .state('list-services', {
            url: '/list-services',
            templateUrl: 'layout/detail-services/list-services.html',
            controller: 'ListServicesCtrl'
        })
        .state('detail-service', {
            url: '/services/:type',
            templateUrl: 'layout/detail-services/service-detail.html',
            controller: 'ServicesCtrl'
        })
        .state('start-project', {
            url: '/start-project/:type',
            templateUrl: 'layout/detail-services/start-project.html',
            controller: 'StartProjectCtrl'
        })
        .state('dev-story', {
            url: '/dev-story',
            templateUrl: 'layout/development-story/dev-story.html',
            controller: 'DevstoryCtrl'
        })
        .state('faq', {
            url: '/faq',
            templateUrl: 'layout/faq/faq.html',
            controller: 'FaqCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | For Agencies. We Skyrocket your Company';
                        $rootScope.meta.description     = '';
                    });
                }]
            }
        })
        .state('get-started', {
            url: '/get-started',
            templateUrl: 'layout/get-quotes/get-started.html',
            controller: 'QuotesCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | Get a free quote';
                        $rootScope.meta.description     = 'Got a project in mind? Let"s build something together, we can help you convert your designs into great and modern websites.';
                    });
                }]
            }
        })
        .state('quote-sent', {
            url: '/quote-sent',
            templateUrl: 'layout/get-quotes/get-thanks.html',
            controller: 'QuoteSendCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | For Agencies. We Skyrocket your Company';
                        $rootScope.meta.description     = '';
                    });
                }]
            }
        })
        .state('how-it-works', {
            url: '/how-it-works',
            templateUrl: 'layout/how-it-works/how-it-works.html',
            controller: 'HowitCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | How This Works';
                        $rootScope.meta.description     = 'Working closely with our clients is our pleasure. Here are the steps toward your project’s completion. You can start with the first one today.';
                    });
                }]
            }
        })
        .state('policy', {
            url: '/our-policy',
            templateUrl: 'layout/policy/our-policies.html',
            controller: 'PolicyCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | Policy';
                        $rootScope.meta.description     = 'See our simple & responsible policies';
                    });
                }]
            }
        })
        .state('pricing', {
            url: '/pricing',
            templateUrl: 'layout/pricing/pricing.html',
            controller: 'PricingCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | Pricing Service';
                        $rootScope.meta.description     = 'Discover our affordable price for your development needs.';
                    });
                }]
            }
        })
        .state('sitemap', {
            url: '/sitemap',
            templateUrl: 'layout/sitemap/sitemap.html',
            controller: 'SitemapsCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | Sitemap';
                        $rootScope.meta.description     = 'Find a page on our site that you are looking for';
                    });
                }]
            }
        })
        .state('service', {
            url: '/service',
            templateUrl: 'layout/services/services.html',
            controller: 'ServiceCtrl',
            resolve: {
                Data: ['$http','$rootScope', function ($http, $rootScope) {
                    $rootScope.$on('$viewContentLoaded', function(){
                        $rootScope.meta.title           = 'KODEKU | Services';
                        $rootScope.meta.description     = 'Discover our services.';
                    });
                }]
            }
        })
        .state('error404', {
            url: '/error404',
            templateUrl: 'layout/error/404.html',
            controller: 'NotFoundCtrl'
        })
        .state('error500', {
            url: '/error500',
            templateUrl: 'layout/error/500.html',
            controller: 'InternalErrorCtrl'
        })
    $locationProvider.html5Mode(true);
}])

// .directive('a', function() {
//     return {
//         restrict: 'E',
//         link: function(scope, elem, attrs) {
//             if(attrs.ngClick || attrs.href === '' || attrs.href === '#'){
//                 elem.on('click', function(e){
//                     e.preventDefault();
//                 });
//             }
//         }
//    };
// })

.directive('cookiesclose', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function() {
                $('.cookies_popup').addClass('closed');
            });
        }
    };
})

.directive('scrolltwo', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                $('[paginationnext]').trigger('click');
            });
        }
    };
})

.directive('paginationnext', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                e.preventDefault();
                var dataActive = $(this).closest('.pagination').find('li.active');
                var dataScroll = dataActive.next().attr('data-scroll');
                if(dataActive.next().length != 0){
                    dataActive.next().addClass('active');
                    dataActive.removeClass('active');
                    $('html,body').animate({scrollTop: $('.'+dataScroll).offset().top }, "slow");
                }
            });
        }
    };
})

.directive('paginationprev', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                e.preventDefault();
                var dataActive = $(this).closest('.pagination').find('li.active');
                var dataScroll = dataActive.prev().attr('data-scroll');
                if(dataActive.prev().length != 0){
                    dataActive.prev().addClass('active');
                    dataActive.removeClass('active');
                    if(dataScroll == 'section-1'){
                        $('html,body').animate({scrollTop: $('.'+dataScroll).offset().top - 100 }, "slow");
                    }else{
                        $('html,body').animate({scrollTop: $('.'+dataScroll).offset().top }, "slow");
                    }
                }
            });
        }
    };
})

.directive('paginationtarget', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                e.preventDefault();
                $('.pagination ul li').removeClass('active');
                $(this).addClass('active');
                var dataScroll = $(this).attr('data-scroll');
                if(dataScroll == 'section-1'){
                    $('html,body').animate({scrollTop: $('.'+dataScroll).offset().top - 100 }, "slow");
                }else{
                    $('html,body').animate({scrollTop: $('.'+dataScroll).offset().top }, "slow");
                }
            });
        }
    };
})

.directive('ournext', function() {
    var trasndone = 30;
    var scale = .9;
    var translatemin = 0;
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                var src = $(this).closest('.our-works-content').find('.our-works-list').find('.our-item.active');
                var scrnotActive = $(this).closest('.our-works-content').find('.our-works-list').find('.our-item').not('.active, .done');
                if(src.nextAll().length > 1){
                    src.removeClass('active').addClass('done').css({
                        'transform': 'translate(0%, -'+trasndone+'px) scale('+scale+')',
                    });
                    src.next().addClass('active').css({
                        'transform': 'translate(0%, 0px) scale(1)',
                        'opacity': '1'
                    });

                    scrnotActive.each(function(elm, i){
                        translatemin = 100 * elm;
                        $(this).css({
                            'transform': 'translate('+translatemin+'%, 0px) scale(1)',
                            'opacity': '1'
                        });
                    })
                }
            });
        }
    };
})

.directive('nextquote', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                var currentPos = $(this).closest('.step-form');
                currentPos.removeClass('active').addClass('done');
                currentPos.next().addClass('active').removeClass('done hided');
            });
        }
    };
})

.directive('backquotes', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                e.preventDefault();
                var currentPos = $(this).closest('.step-form');
                currentPos.removeClass('active').removeClass('done').addClass('hided');
                currentPos.prev().addClass('active');
            });
        }
    };
})

.directive('startover', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                e.preventDefault();
                $('.step-form').addClass('hided').removeClass('done active');
                $('.step-form').first().removeClass('done hided').addClass('active');
            });
        }
    };
})

.directive('footercollapse', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                e.preventDefault();
                var current = $(this).parent();
                if(current.hasClass('actived')){
                    $('.footer-menu .item-footer').removeClass('actived').find('.content-hide').slideUp();
                    current.removeClass('actived');
                }else{
                    $('.footer-menu .item-footer').removeClass('actived').find('.content-hide').slideUp();
                    current.addClass('actived').find('.content-hide').slideDown();
                }
            });
        }
    };
})

.directive('ourcollapse', function(){
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                e.preventDefault();
                var current = $(this).parent();
                if(current.hasClass('actived')){
                    $('.item-list').removeClass('actived').find('.content-hide').slideUp();
                    current.removeClass('actived');
                }else{
                    $('.item-list').removeClass('actived').find('.content-hide').slideUp();
                    current.addClass('actived').find('.content-hide').slideDown();
                }
            });
        }
    }
})


.directive('ourprev', function() {
    var sizeItem = 0;
    return {
         restrict: 'A',
            link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                var src = $(this).closest('.our-works-content').find('.our-works-list').find('.our-item.active');
                var scrnotActive = $('.our-works-content').find('.our-works-list').find('.our-item').not('.active, .done');
                if(src.prev().length != 0){
                    sizeItem = sizeItem + 100;
                    src.removeClass('active').removeClass('done').css({
                        'transform': 'translate(100%, 0px) scale(1)',
                    });
                    src.prev().addClass('active').removeClass('done').css({
                        'transform': 'translate(0%, 0px) scale(1)',
                        'opacity': '1'
                    });
                    scrnotActive.each(function(index){
                        var indexing = index + 2;
                        var transitioning = indexing * 100;
                        $(this).css({
                            'transform': 'translate('+transitioning+'%, 0px) scale(1)',
                        });
                    })
                }
            });
        }
    };
})

.directive('scrollToItem', function() {
    return {
    restrict: 'A',
    scope: {
        scrollTo: "@"
    },
    link: function(scope, $elm,attr) {

        $elm.on('click', function() {
            $('html,body').animate({scrollTop: $(scope.scrollTo).offset().top }, "slow");
        });
    }
}})

.directive('floatcontactus', function() {
    return {
        restrict: 'A',
        link: function(scope, $elm,attr) {
            $elm.on('click', function(e) {
                e.preventDefault();
                // angular.element(this).fadeOut();
                angular.element('.contact__us__content').addClass('opended');
            });
        }
    }
})

.directive('closefloat', function(){
    return{
        restrict: 'A',
        link: function(scope, $elm, attr){
            $elm.on('click', function(e){
                angular.element('.contactus__float button').fadeIn();
                angular.element('.contact__us__content').removeClass('opended');
            });
        }
    }
})

.directive('notifyGtm', function(){

    var dataLayer = window.dataLayer = window.dataLayer || [];
    function link(scope, element, attributes, ngModel)
    {
      element.bind('change', function() {
        var el = element && element[0] ? element[0] : '';
        if (el) {
            dataLayer.push({
                event: 'ngNotifyGtm',
                attributes: {
                    element: el,
                    modelValue: ngModel.$modelValue,
                    viewValue: ngModel.$viewValue
                }
            });
        }
      });
    }

    return {
        require   : 'ngModel',
        restrict  : 'A',
        link      : link,
        scope: {
            ngModel: '='
        }
    };

})

.service('TextEffect', function(){
    var TextEffect = {};
    TextEffect.setup = function(){
        ;(function(window) {

            'use strict';

            /**
             * Equation of a line.
             */
            function lineEq(y2, y1, x2, x1, currentVal) {
                // y = mx + b
                var m = (y2 - y1) / (x2 - x1),
                    b = y1 - m * x1;

                return m * currentVal + b;
            }

            function TextFx(el) {
                this.el = el;
                this._init();
            }

            TextFx.prototype.effects = {
                'fx1' : {
                    in: {
                        duration: 1000,
                        delay: function(el, index) { return 75+index*40; },
                        easing: 'easeOutElastic',
                        elasticity: 250,
                        opacity: {
                            value: 1,
                            easing: 'easeOutExpo',
                        },
                        translateY: ['-50%','0%']
                    },
                    out: {
                        duration: 400,
                        delay: function(el, index) { return index*40; },
                        easing: 'easeOutExpo',
                        opacity: 0,
                        translateY: '100%'
                    }
                }
            };

            TextFx.prototype._init = function() {
                this.el.classList.add('letter-effect');
                // Split word(s) into letters/spans.
                charming(this.el, {classPrefix: 'letter'});
                this.letters = [].slice.call(this.el.querySelectorAll('span'));
                this.lettersTotal = this.letters.length;
            };

            TextFx.prototype._stop = function() {
                anime.remove(this.letters);
                this.letters.forEach(function(letter) { letter.style.WebkitTransform = letter.style.transform = ''; });
            };

            TextFx.prototype.show = function(effect, callback) {
                this._stop();
                arguments.length ? this._animate('in', effect, callback) : this.letters.forEach(function(letter) { letter.style.opacity = 1; });
            };

            TextFx.prototype.hide = function(effect, callback) {
                this._stop();
                arguments.length ? this._animate('out', effect, callback) : this.letters.forEach(function(letter) { letter.style.opacity = 0; });
            };

            TextFx.prototype._animate = function(direction, effect, callback) {
                var effecSettings = typeof effect === 'string' ? this.effects[effect] : effect;

                if( effecSettings.perspective != undefined ) {
                    this.el.style.WebkitPerspective = this.el.style.perspective = effecSettings.perspective + 'px';
                }
                if( effecSettings.origin != undefined ) {
                    this.letters.forEach(function(letter) {
                        letter.style.WebkitTransformOrigin = letter.style.transformOrigin = effecSettings.origin;
                    });
                }

                // Custom effect
                var iscustom = this._checkCustomFx(direction, effect, callback);

                var animOpts = effecSettings[direction],
                    target = this.letters;

                target.forEach(function(t,p) {
                    if( t.innerHTML === ' ' ) {
                        target.splice(p, 1);
                    }
                });

                animOpts.targets = target;

                if( !iscustom ) {
                    animOpts.complete = callback;
                }

                anime(animOpts);
            };

            /**
             * Extra stuff for an effect.. this is just an example for effect 17.
             * TODO! (for now, just some quick way to implement something different only for fx17)
             */
            TextFx.prototype._checkCustomFx = function(direction, effect, callback) {
                var custom = typeof effect === 'string' && effect === 'fx17' && direction === 'out';

                if( custom ) {
                    var tmp = document.createElement('div');
                    tmp.style.width = tmp.style.height = '100%';
                    tmp.style.top = tmp.style.left = 0;
                    tmp.style.background = '#e24b1e';
                    tmp.style.position = 'absolute';
                    tmp.style.WebkitTransform = tmp.style.transform = 'scale3d(0,1,1)';
                    tmp.style.WebkitTransformOrigin = tmp.style.transformOrigin = '0% 50%';
                    this.el.appendChild(tmp);
                    var self = this;
                    anime({
                        targets: tmp,
                        duration: 400,
                        easing: 'easeInOutCubic',
                        scaleX: [0,1],
                        complete: function() {
                            tmp.style.WebkitTransformOrigin = tmp.style.transformOrigin = '100% 50%';
                            anime({
                                targets: tmp,
                                duration: 400,
                                easing: 'easeInOutCubic',
                                scaleX: [1,0],
                                complete: function() {
                                    self.el.removeChild(tmp);
                                    if( typeof callback === 'function' ) {
                                        callback();
                                    }
                                }
                            });
                        }
                    });
                }

                return custom;
            };

            window.TextFx = TextFx;

        })(window);


        (function(){
        //document.addEventListener('DOMContentLoaded',function(){
            var Slide = function(el) {
                    this.el = el;
                    this.txt = new TextFx(this.el.querySelector('.title'));
                },
                // The Slideshow obj.

                Slideshow = function(e) {
                    this.e = e;
                    this.current = 0;
                    this.slides = [];
                    var self = this;
                    [].slice.call(this.e.querySelectorAll('div.slide')).forEach(function(slide) {
                        self.slides.push(new Slide(slide));
                    });

                    this.slidesTotal = this.slides.length;
                    this.effect = this.e.getAttribute('data-effect');
                };

            Slideshow.prototype._navigate = function(direction) {
                if( this.isAnimating ) {
                    return false;
                }
                this.isAnimating = true;

                var self = this, currentSlide = this.slides[this.current];

                this.current = direction === 'next' ? (this.current < this.slidesTotal - 1 ? this.current + 1 : 0) : (this.current = this.current > 0 ? this.current - 1 : this.slidesTotal - 1);
                var nextSlide = this.slides[this.current];

                var checkEndCnt = 0, checkEnd = function() {
                    ++checkEndCnt;
                    if( checkEndCnt === 2 ) {
                        currentSlide.el.classList.remove('slide--current');
                        nextSlide.el.classList.add('slide--current');
                        self.isAnimating = false;
                    }
                };

                // Call the TextFx hide method and pass the effect string defined in the data-effect attribute of the Slideshow element.
                currentSlide.txt.hide(this.effect, function() {
                    currentSlide.el.style.opacity = 0;
                    checkEnd();
                });
                // First hide the next slide´s TextFx text.
                nextSlide.txt.hide();
                nextSlide.el.style.opacity = 1;
                // And now call the TextFx show method.
                nextSlide.txt.show(this.effect, function() {
                    checkEnd();
                });
            };

            Slideshow.prototype.next = function() { this._navigate('next'); };

            Slideshow.prototype.prev = function() { this._navigate('prev');	};

            function init(){
                [].slice.call(document.querySelectorAll('.content')).forEach(function(el) {
                    var slideshow = new Slideshow(el.querySelector('.slideshow'));
                    el.querySelector('.actions').firstElementChild.addEventListener('click', function() { slideshow.prev(); });
                    el.querySelector('.actions').lastElementChild.addEventListener('click', function() { slideshow.next(); });
                    if($("#service-text-trigger").length){
                        setInterval(function () {document.getElementById("service-text-trigger").click();}, 5000);
                    }
                });
            }
            setTimeout(function(){
                init();
            }, 500)

        //});
        })();

    }
    return TextEffect;

})

.service('GTMService', function(){
    var dataLayer = window.dataLayer = window.dataLayer || [];

    function push(obj)
    {
        dataLayer.push(obj);
    }

    push.trackEvent = function(obj) {
        var attr = obj.attributes;
        var abort;
        angular.forEach(['category', 'action'], function(el) {
            if (typeof attr[el] === 'undefined') console.log('trackEvent: Missing required property ' + el + '. Aborting hit.');
            abort = true;
        });

        if (abort) return;

        push({
            'event': 'ngTrackEvent',
            'attributes': {
                'category': attr.category,
                'action': attr.action,
                'label': attr.label,
                'value': attr.value,
                'nonInteraction': attr.nonInteraction
            }
        });

    }

    return push;

})

.run(function ($rootScope, $state, $stateParams, $window, kodekuService, $timeout) {
    Pace.options = {
        startOnPageLoad : false
    }
    $('.img-hero').imagesLoaded().always( function( instance ) {
        console.log('all images loaded');
        Pace.start();
        
    });
    Pace.on('done',function(){
        if(firstState == false){
            $('.preload-blocker').removeClass('section-gradient open');
        }else{
            $timeout(function () {
                $('.preload-blocker').removeClass('section-gradient open');
            },1000);
            firstState = false;
        }
    });
    $rootScope.meta  = {};
    $rootScope.meta.title       = 'KODEKU | Web and App Development Agency';
    $rootScope.meta.description = 'We producing refined Website, Mobile Application and UI/UX solutions with a broad knowledge and experience of building themes and plugins for various CMSes including Wordpress, Woocommerce & Magento.';
    $rootScope.meta.url         = window.location.href;
    $rootScope.meta.image       = window.globalVariable.appUrl + 'assets/images/kodekulink.png';

    $rootScope.$state          = $state;
	$rootScope.$stateParams    = $stateParams;

    var firstState = true;
    $rootScope.contact      = {};
    $rootScope.contactUrl   = [];
    $rootScope.contact.phone_code   = '+62';
    if( $window.localStorage['contact'] )
    {
        var contactUs = JSON.parse( $window.localStorage['contact'] );
        $rootScope.contact.fullname     = contactUs.fullname;
        $rootScope.contact.email        = contactUs.email;
        $rootScope.contact.phone_code   = contactUs.phone_code;
        $rootScope.contact.phone_number = contactUs.phone_number;
    }

    $rootScope.emailValidationFooter = function (emailAddress) {
        var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        return pattern.test(emailAddress);
    };

    $rootScope.navigateTo = function(state,params){
        if($state.current.name !== state){
          $('.preload-blocker').addClass('section-gradient open');
          $timeout(function () {
              $state.go(state,params);
          },1000);
        }
        else{
          $('.preload-blocker').removeClass('section-gradient open');
        }
  
      };

    $rootScope.submitContact = function(event){
        var errorCount = [];

        if($rootScope.contact.fullname == "" || $rootScope.contact.fullname == undefined){
            errorCount.push('error name');
            angular.element('.contact__us__content .contact_names').addClass('has-error').attr('placeholder', 'Sorry, name is empty');
        }else{ angular.element('.contact__us__content .contact_names').removeClass('has-error').attr('placeholder', 'Your Name*'); }

        if($rootScope.contact.email == "" || $rootScope.contact.email == undefined){
            errorCount.push('error email');
            angular.element('.contact__us__content .contact_emails').addClass('has-error').attr('placeholder', 'Sorry, email is empty');
        }else if($rootScope.emailValidationFooter($rootScope.contact.email) != true){
            errorCount.push('error email');
            angular.element('.contact__us__content .contact_emails').addClass('has-error').attr('placeholder', 'Email address not valid');
        }else{angular.element('.contact__us__content .contact_emails').removeClass('has-error').attr('placeholder', 'Email Address*');}

        if($rootScope.contact.phone_number == "" || $rootScope.contact.phone_number == undefined){
            errorCount.push('error phone');
            angular.element('.contact__us__content .contact_phone').addClass('has-error').attr('placeholder', 'Sorry, phone number is empty');
        }else{ angular.element('.contact__us__content .contact_phone').removeClass('has-error').attr('placeholder', 'Phone Number*'); }

        if($rootScope.contact.description == "" || $rootScope.contact.description == undefined){
            errorCount.push('error desc');
            angular.element('.contact__us__content .contact_desc').addClass('has-error').attr('placeholder', 'Sorry, description is empty');
        }else{angular.element('.contact__us__content .contact_desc').removeClass('has-error').attr('placeholder', 'Your Instruction');}

        if(errorCount.length == 0){
            angular.element('.actions_foot').addClass('progress');
            kodekuService.saveContact({
                fullname        : $rootScope.contact.fullname,
                email           : $rootScope.contact.email,
                phone_number    : $rootScope.contact.phone_number,
                description     : $rootScope.contact.description,
                file            : $rootScope.contactUrl,
            }).then(function(data){
                if(data.status)
                {
                    var contact = {
                                    fullname        : $rootScope.contact.fullname,
                                    email           : $rootScope.contact.email,
                                    phone_number    : $rootScope.contact.phone_number
                                  };
                    $window.localStorage['contact'] = JSON.stringify(contact);
                    angular.element('.actions_foot').removeClass('progress').addClass('success');
                    angular.element('.contact__us__content input, .contact__us__content textarea').val('');
                    $timeout(function(){
                        $state.go('home');
                    }, 1000);
                }
            });
            event.preventDefault();
            event.stopPropagation();
        }
    };

    $rootScope.subsribeFooter          = {};
    $rootScope.subsribeFooter.required = '';

    $rootScope.submitSubscribeFooter = function(event){

        if($rootScope.subsribeFooter.email == "" || $rootScope.subsribeFooter.email == undefined)
        {
            // $rootScope.subsribeFooter.required = 'has-error';
            $rootScope.subsribeFooter.required = 'invalid';
            angular.element('input.subscribe').attr('placeholder', 'Email is Empty');
        }else if($rootScope.emailValidationFooter( $rootScope.subsribeFooter.email ) != true)
        {
            // $rootScope.subsribeFooter.required = 'has-error';
            $rootScope.subsribeFooter.required = 'invalid';
            angular.element('input.subscribe').val('').attr('placeholder', 'Email is not valid');
        }else
        {
            $rootScope.subsribeFooter.required = 'progress';
            kodekuService.subscribe({
                email : $rootScope.subsribeFooter.email
            }).then(function(data){
                console.log(data);
                if(data.status)
                {
                    if(data.type == 'success'){
                        $rootScope.subsribeFooter.required = 'success';
                        angular.element('input.subscribe').val('').attr('placeholder', 'Success! Check your email');
                    }else if(data.type == 'registered'){
                        $rootScope.subsribeFooter.required = 'success';
                        angular.element('input.subscribe').val('').attr('placeholder', 'Email is already registered');
                    }
                }
            });
        }
        event.preventDefault();
        event.stopPropagation();
    };

    angular.element(document).ready(function () {

        setTimeout(function(){
            angular.element('.number-contact').niceSelect();
        },500);


        $(window).scroll(function() {
            // for change color
            var paginate = $(".pagination");
            var paginate_position = $(".pagination").offset().top;
            var paginate_height = $(".pagination").height() - 200;

            var toCross = $(".section-gradient").add('.bg-color-gradient');

            if($(".section-gradient").length){
                var toCross_position = $(".section-gradient").add('.bg-color-gradient').offset().top;
            }else{}
            var toCross_height = $(".section-gradient").add('.bg-color-gradient').height();

            if (paginate_position + paginate_height  < toCross_position) {
                paginate.removeClass('light');
            } else if (paginate_position > toCross_position + toCross_height) {
                paginate.removeClass('light');
            } else {
                paginate.addClass('light');
            }
            
            var height_hero = $('.hero.section-1').innerHeight();
            if($('.home-pages').length){
                if ($(this).scrollTop() > height_hero) {
                    $('.headermenu').addClass('scrolled');
                    if ($('.headermenu.in-gradient').hasClass('scrolled')){
                        $('.main-logo').find('img').attr('src', './assets/images/logo-black.svg');
                    }
                    else{}
                } else {
                    $('.headermenu').removeClass('scrolled');
                    $('.headermenu.in-gradient .main-logo').find('img').attr('src', './assets/images/logo-white.svg');
                }
            }else{
                if ($(this).scrollTop() > 50) {
                    $('.headermenu').addClass('scrolled');
                    if ($('.headermenu.in-gradient').hasClass('scrolled')){
                        $('.main-logo').find('img').attr('src', './assets/images/logo-black.svg');
                    }
                    else{}
                } else {
                    $('.headermenu').removeClass('scrolled');
                    $('.headermenu.in-gradient .main-logo').find('img').attr('src', './assets/images/logo-white.svg');
                }
            }

            // pagination show hide
            var heightSection1 = $('.section-1').innerHeight() - 200;
            var arrowWrapper = $('.arrow-wrapper').innerHeight() / 3;
            if ($('.hero').length){
                if($(this).scrollTop() > heightSection1){
                    $('.arrow-wrapper').addClass('hided');
                    $('.pagination-container').addClass('fadein');
                    $('.nav-menu').addClass('fadein');
                }
                else{
                    $('.arrow-wrapper').removeClass('hided');
                    $('.pagination-container').removeClass('fadein');
                    $('.nav-menu').removeClass('fadein');
                }
            }
            else{
                if ($(this).scrollTop() > arrowWrapper){
                    $('.arrow-wrapper').addClass('hided');
                    $('.pagination-container').addClass('fadein');
                    $('.nav-menu').addClass('fadein');
                }
                else{
                    $('.arrow-wrapper').removeClass('hided');
                    $('.pagination-container').removeClass('fadein');
                    $('.nav-menu').removeClass('fadein');
                }
            }
            



            // for highlight dots
            var $navigationLinks = $('.pagination>ul>li');
            var $sections = $('*[data-id*="section-"]');
            var $sectionsReversed = $($('*[class*="section-"]').get().reverse());
            var sectionIdTonavigationLink = {};

            $sections.each(function() {
                sectionIdTonavigationLink[$(this).attr('data-id')] = $('.pagination>ul>li[data-scroll=' + $(this).attr('data-id') + ']');
            });

            var scrollPosition = $(window).scrollTop();

            if(scrollPosition + $(window).height() == $(document).height()){
              $('.pagination>ul>li').removeClass('active');
              $('.pagination>ul>li:last-child').addClass('active');
            }

            $('*[data-id*="section-"]').each(function() {
                var sectionTop = $(this).offset().top - 300;
                var id = $(this).attr('data-id');

                if(scrollPosition >= sectionTop) {
                   // console.log(id);
                    $('.pagination>ul>li').removeClass('active');
                    $('.pagination>ul>li[data-scroll=' + id + ']').addClass('active');
                }
            });

            //change transparent header after scroll

            var height_hero = $('.headermenu').height();

            if ($(this).scrollTop() > height_hero) {
                $('.headermenu').addClass('scrolled');
            } else {
                $('.headermenu').removeClass('scrolled');
            }
        });

    });

    $rootScope.$on('$viewContentLoaded', function(){
        $timeout(function(){
            $('.dialog-box.logo').addClass('inview');
            $rootScope.meta.url   = window.location.href;
        },2500);

        $timeout(function(){
            $('.close[logoload-close]').trigger('click');
            $rootScope.meta.url   = window.location.href;
        },6500);



        // if(firstState == false)
        // {
        //     $('.preload-blocker').removeClass('section-gradient open');
        // }
        // else{
        //     $timeout(function () {
        //         $('.preload-blocker').removeClass('section-gradient open');
        //     },1000);
        //     firstState = false;
        // }
    });

    var dataLayer = window.dataLayer = window.dataLayer || [];

    $rootScope.$on('$routeChangeSuccess', function() {
        dataLayer.push({
            event: 'ngRouteChange',
            attributes: {
                route: $location.path()
            }
        });
    });

})
