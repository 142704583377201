'use strict';

angular.module('myApp.pricing', ['ngRoute'])

.controller('PricingCtrl', ['$scope', '$window', '$timeout', '$stateParams', '$http', '$sce', function($scope, $window, $timeout, $stateParams, $http , $sce) {
    $scope.list;
    $http.get('layout/pricing/data.json').then(function(res) {
        $scope.data = res.data;
        $scope.list = $scope.data[$scope.itemActive];
        console.log($scope.list)
    });
    
    $scope.itemActive = 'mobile';

    $scope.activeChange = function(e){
        $scope.itemActive = e;
        $scope.list = $scope.data[$scope.itemActive];
    }

    $scope.renderHtml = function (htmlCode) {
        return $sce.trustAsHtml(htmlCode);
    };

	$scope.dataLogo = 'assets/images/logo-white.svg';
	$scope.headermenu = 'in-gradient';

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            },
        ]
    };
    angular.element(document).ready(function () {
        $('.menu-side ul').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            arrows: false,
            centerMode: true,
        });
        
        $('.menu-side ul li').click(function(e){
            e.preventDefault();
            var slideIndex = $(this).attr("data-slick-index");
            $('.menu-side ul').slick('slickGoTo', slideIndex);
        });

        $('.menu-side ul').on('afterChange', function(event, slick, currentSlide, nextSlide){
            $('.service-boxes').addClass('invisible').removeClass('inview');
            $('.service-boxes#'+currentSlide).addClass('inview').removeClass('invisible'); 
            $('.service-boxes').not('#'+currentSlide).addClass('invisible').removeClass('inview');
            $scope.itemActive = $('#'+currentSlide).attr('to-swipe');
            console.log('swipe');
        });
    });
}]);