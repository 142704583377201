'use strict';

angular.module('myApp.storyEmail', [])

.directive('storyEmail', storyEmail);

function storyEmail($timeout){
  var link = function(scope,el,attrs){
    angular.element(document).ready(function(){
      var $emails = document.querySelectorAll('.Email');
      var $animationSection = document.querySelector('#email-animation');
      var $textSection = document.querySelector('.review-text');
      var $animationWrapper = document.querySelectorAll('.EmailAnimation-wrapper')[0];

      var FIRST_MESSAGE = -400;
      var FIXED_OFFSET = 700;
      var FIXED_TOP_OFFSET = 50;
      var FIXED_WIDTH = el.parent().width();
      var ELEMENT_TOP_OFFSET = $(el).offset().top;
      var clamp = function clamp(value, min, max) {
        return Math.min(Math.max(value, min), max);
      };
      $('.EmailAnimation').imagesLoaded()
      .always( function( instance ) {
        console.log('story')
        var effectsSpec = [{
          start: $(el).offset().top,
          end: FIXED_OFFSET + ELEMENT_TOP_OFFSET,
          active: function active() {
            $animationWrapper.style.position = 'fixed';
            $animationWrapper.style.top = FIXED_TOP_OFFSET + 'px';
            $animationWrapper.style.left = 0;
            $animationWrapper.style.right = 0;
          },
          inactive: function inactive() {
            $animationWrapper.style.position = null;
            $animationWrapper.style.top = null;
            $animationWrapper.style.left = null;
            $animationWrapper.style.right = null;
            // TODO(stephen): hack! :<
            // this will remove the styles on animationWrapper, including the ones set by the
            // next effect. Should mark that effect deactivated so it'll get re-written.

            effectsSpec[1]._activated = false;
          }
        }, {
          start: FIXED_OFFSET + ELEMENT_TOP_OFFSET,
          active: function active() {
            $animationWrapper.style.position = 'absolute';
            $animationWrapper.style.top = FIXED_OFFSET + 'px';
            $animationWrapper.style.left = 0;
            $animationWrapper.style.right = 0;

          }
        }];

        if (window.Modernizr.touch) {
          (function () {
            var totalOffset = -20;
            var mobileOffset = 0;

            $emails.forEach(function ($email, i) {
              mobileOffset = 30;

              $email.style.transform = 'translate3d(0, ' + totalOffset + 'px, 0) scale(0.9 + 1)'; // eslint-disable-line no-param-reassign, max-len
              $email.style['-webkit-transform'] = 'translate3d(0, ' + totalOffset + 'px, 0) scale(' + (0.82 + 0.02 * i) + ')'; // eslint-disable-line no-param-reassign, max-len

              totalOffset += mobileOffset;
            });

            // $animationSection.style.height = '1000px';
          })();
        }

        var emailPos = function(updateScrollY){
          var lastScrollY = updateScrollY;
          $emails.forEach(function ($email, i) {
            // trigger location
            // const bottom = -730 + (i * 50);
            var bottom = ELEMENT_TOP_OFFSET - 530 + i * 50;

            // leading space above element
            var base = 10 * i; // original was 40, increased it

            // translation distance (up/down movement)
            var translation = 100 + 100 * i;

            // movement speed? this needs to be understood better.
            var factor = 1 - 0.1 - i * 0.08;

            // translate things
            var calcTranslate = -1 * (clamp((lastScrollY - bottom - 400) / factor, base, translation) - translation - base);

            var scale = clamp(1 - 0.05 * $emails.length + 0.01 * i, 0, 1);

            // apply transforms
            $email.style.transform = 'translate3d(0, ' + calcTranslate + 'px, 0) scale(' + scale + ')'; // eslint-disable-line no-param-reassign, max-len
            $email.style['-webkit-transform'] = 'translate3d(0, ' + calcTranslate + 'px, 0) scale(' + scale + ')'; // eslint-disable-line no-param-reassign, max-len


          });
        }

        var updateEffect = function updateEffect(updateScrollY) {
          var lastScrollY = window.pageYOffset;
          // use breakpoints to animate on mobile devices
          // if (window.Modernizr.touch) {
          //   return;
          // }

          effectsSpec.forEach(function (effect) {
            if (lastScrollY >= effect.start && (!effect.end || lastScrollY < effect.end)) {
              if (effect.active && !effect._activated) {
                effect.active();
                effect._activated = true; // eslint-disable-line no-param-reassign, max-len
              }
            } else {
              if (effect.inactive && effect._activated) {
                effect.inactive();
                effect._activated = false; // eslint-disable-line no-param-reassign, max-len
              }
            }
          });
          if(window.pageYOffset >= ELEMENT_TOP_OFFSET){
            emailPos(lastScrollY);
          }
        };

        if(window.pageYOffset >= (ELEMENT_TOP_OFFSET - 100)){
            emailPos(window.pageYOffset);
        }else{
            emailPos(0);
        }

        window.addEventListener('scroll', updateEffect);

        $timeout(function(){
          $('.EmailAnimation').addClass('rendered');
        },500);
      });
    });
  }

  return {
      restrict: 'E',
      scope: {
          list: '=',
      },
      templateUrl: 'layout/directives/devstory/devstory.template.html',
      link: link
  };

}
