'use strict';

angular.module('myApp.errors', ['ngRoute'])

.controller('NotFoundCtrl', ['$scope', '$timeout', function($scope, $timeout) {
	$scope.dataLogo = 'assets/images/logo-white.svg';
	$scope.headermenu = 'in-gradient';
    $scope.paginateColor = 'light';
	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            }
        ]
    };

    $scope.animateIn = function($el) {
      $el.addClass('inview'); // this example leverages animate.css classes
    };
}])

.controller('InternalErrorCtrl', ['$scope', '$timeout', function($scope, $timeout) {
	$scope.dataLogo = 'assets/images/logo-white.svg';
	$scope.headermenu = 'in-gradient';
    $scope.paginateColor = 'light';
	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            }
        ]
    };

    $scope.animateIn = function($el) {
      $el.addClass('inview'); // this example leverages animate.css classes
    };
}]);