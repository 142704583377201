'use strict';
// Declare app level module which depends on views, and components
window.globalVariable = {
    apiUrl            : "https://api.kodeku.net/",
    fileUploadUrl     : "https://api.kodeku.net/fileupload/"
  };

angular.module('myApp.contact', ['ngRoute'])

.controller('CotactCtrl', ['$scope', '$timeout', function($scope, $timeout) {
	$scope.dataLogo = 'assets/images/logo-white.svg';
	$scope.headermenu = 'in-gradient';

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            }
        ]
    };

    $(document).on('click', '.contact__us__content .area_upload', function(){
        $('#fileuploadcontact').click();
    });

    $(document).on('click', '.items-result .remove_action', function(e){
        $('.items-result').find('span').text('');
        $('.items-result').removeClass('in');
        $('.content').find('.upl_area').show();
    });

    // setTimeout(function(){
    $('#fileuploadcontact').fileupload({
        url : window.globalVariable.fileUploadUrl,
        dataType: 'json',
        dropZone: $('.custom_dropzone'),
        add: function (e, data) {
            if(data.originalFiles[0]['size'] > 2560000) {
                $('.area_upload .txt').text('Filesize to Big');
            }else{
                $('.area_upload .txt').text('Uploading...');
                $('.area_upload').addClass('uploading');
                data.submit();
            }
        },
        done: function (e, data) {
            $('.upl_area').hide();
            $('.area_upload .txt').text('Add file or drop here');
            $('.area_upload').removeClass('uploading');
            $('.items-result').addClass('in');
            $.each(data.result.files, function (index, file) {
                $('.items-result ul li span').text(file.name);
                $rootScope.contactUrl.push(file.url);
            });
        }
    });
    // },500);
}]);