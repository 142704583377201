'use strict';

angular.module('myApp.service', ['ngRoute'])

  .controller('ServiceCtrl', ['$scope', '$timeout', '$interval', '$location', function ($scope, $timeout, $interval, $location) {

    $scope.dataLogo = 'assets/images/logo-white.svg';
    $scope.headermenu = 'in-gradient';
    $scope.itemActive = 1;

    $scope.isActive = function (viewLocation) {
      var active = (viewLocation === $location.path());
      return active;
    };
  
    $scope.autoplay = function(play){
        if(play){
            $scope.auto = $interval(function () {
                if($scope.itemActive >= 7){
                    $scope.itemActive = 1;
                }else{
                    $scope.itemActive += 1;
                }
                // $scope.animateSVG();
            }, 5000);
        }else{
            $interval.cancel($scope.auto);
            $scope.auto = null;
        }
    }
  
    $scope.sequence = function(first){
        if(first){
            $timeout(function(){
                $scope.autoplay(true);
            },100);
        }else{
            $scope.itemActive += 1;
            $scope.autoplay(true);
        }
    }
  
    $scope.activeChange = function(e){
        $scope.itemActive = e;
        $scope.autoplay(false);
        $timeout(function(){
          $scope.sequence(false);
        },5000);
    }
  
    $timeout(function(){
        $scope.sequence(true);
    },1000);

    $scope.services = [
      {
        title: 'Front-End',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons5.svg',
        price: '$10/hour',
        value: 'frontend',
        default: false
      },
      {
        title: 'Backend System',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons8.svg',
        price: '$18/hour',
        value: 'wordpress',
        default: false
      },
      {
        title: 'E-Commerce',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons6.svg',
        price: '$8/hour',
        value: 'maintenance',
        default: false
      },
      {
        title: 'Product',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons9.svg',
        price: '$18/hour',
        value: 'backend',
        default: false
      },
      {
        title: 'Mobile App',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons1.svg',
        price: '$20/hour',
        value: 'mobile',
        default: true
      },
      {
        title: 'UI/UX',
        titlesub: 'Design',
        icons: 'assets/images/icons/icons3.svg',
        price: '$12/hour',
        value: 'uiux',
        default: false
      },
      {
        title: 'Front-End',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons5.svg',
        price: '$10/hour',
        value: 'frontend',
        default: false
      },
      {
        title: 'Backend System',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons8.svg',
        price: '$18/hour',
        value: 'wordpress',
        default: false
      },
      {
        title: 'E-Commerce',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons6.svg',
        price: '$8/hour',
        value: 'maintenance',
        default: false
      },
      {
        title: 'Product',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons9.svg',
        price: '$18/hour',
        value: 'backend',
        default: false
      },
      {
        title: 'Mobile App',
        titlesub: 'Development',
        icons: 'assets/images/icons/icons1.svg',
        price: '$20/hour',
        value: 'mobile',
        default: true
      },
      {
        title: 'UI/UX',
        titlesub: 'Design',
        icons: 'assets/images/icons/icons3.svg',
        price: '$12/hour',
        value: 'uiux',
        default: false
      }
    ];
  }])
  .directive('circleChoose', ['$timeout', '$rootScope', function ($timeout, $rootScope) {
    return {
      restrict: 'E',
      templateUrl: './layout/services/circle.template.html',
      scope: {
        lists: "=",
        animate: "="
      },
      link: function (scope, elm, attrs) {
        scope.isActive = 0;
        var parents = elm[0].children[0].children[0],
          elems = parents.children;

        var nbOptions = 8,
          angleStart = -360;
        var auto = "";

        scope.rotate = function (li, d) {
          $({
            d: angleStart
          }).animate({
            d: d
          }, {
            step: function (now) {
              $(li).css({
                transform: 'rotate(' + now + 'deg)'
              });
            },
            duration: 0
          });
        }

        scope.setRoundHeight = function () {
          var roundSelector = $(".roundup");
          roundSelector.each(function () {
            var roundHeight = $(this).width();
            $(this).css("height", roundHeight + "px");
          });
        }

        scope.toggleOptions = function (s) {
          $(s).toggleClass('open');
          var li = $(s).find('li');
          var deg = 360 / li.length;
          for (var i = 0; i < li.length; i++) {
            var d = i * deg;
            $(s) ? scope.rotate(li[i], d) : scope.rotate(li[i], angleStart);
          }
        }

        scope.switchContent = function() {
          var obj = $(".roundup li a");
          obj.on("click", function(e) {
            var activeClass = "active",
              numItems = $(".roundup li a").length,
              rotationIncrement = 360 / numItems,
              clickedIndex = $(this).parent().index(),
              previousActiveIndex = $(".roundup li.active a").parent().index(),
              previousRotation = previousActiveIndex * rotationIncrement,
              newRotation = clickedIndex * rotationIncrement,
              distance = newRotation - previousRotation,

              imgactive = $('.roundup li a.active').find('img').data("active"),
              img = $('.roundup li a.active').find('img').attr('src'),
              active = $(this).find('img').data('active');

            $(".roundup").css({
              transform: 'rotateZ(-' + (clickedIndex * rotationIncrement) + 'deg)'
            });

            e.preventDefault();

            if ($(this).hasClass('active')) {
              return false;
            }

            obj.removeClass(activeClass);
            $(this).toggleClass(activeClass);

            $timeout(function() {
              scope.isActive = clickedIndex;
            });

            $('.item-slider').children().css('opacity', '0');

            // scope.$apply();

          });
        }

        $timeout(function () {
          scope.setRoundHeight();
          scope.toggleOptions('.roundup');
          scope.switchContent();
        }, 200);

      },
      controller: function () {

      }
    }
  }])
