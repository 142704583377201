'use strict';

angular.module('myApp.home', ['ngRoute','myApp.storyEmail'])

  .controller('HomeCtrl', ['$scope', '$timeout', '$location', 'TextEffect', 'kodekuService', function ($scope, $timeout, $location, TextEffect, kodekuService) {
    // TextEffect.setup();
    
    $scope.subscribe          = {};
    $scope.subscribe.required = '';
    $scope.subscribe.success  = '';

    $scope.dataLogo           = 'assets/images/logo-white.svg';
    $scope.headermenu = 'in-gradient';
    $scope.homepagesscroll    = 'home-pages';
    $scope.slideDownBounce = 'slideDownBounce';

    $scope.isActive = function (viewLocation) {
      var active = (viewLocation === $location.path());
      return active;
    };

    $scope.animateIn = function ($el) {
      $el.removeClass('invisible');
      $el.addClass('inview'); // this example leverages animate.css classes
    };

    $scope.pagination = {
      onepage: [{
          classes_targer: 'section-1',
          active_state: 'active'
        },
        {
          classes_targer: 'section-2',
        },
        {
          classes_targer: 'section-3',
        },
        {
          classes_targer: 'section-4',
        },
        {
          classes_targer: 'section-footer',
        }
      ]
    };


    $scope.services = [
    {
      title: 'Front-End',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons5.svg',
      price: '$10/hour',
      value: 'frontend',
      default: false
    },
    {
      title: 'Backend System',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons8.svg',
      price: '$18/hour',
      value: 'wordpress',
      default: false
    },
    {
      title: 'E-Commerce',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons6.svg',
      price: '$8/hour',
      value: 'maintenance',
      default: false
    },
    {
      title: 'Product',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons9.svg',
      price: '$18/hour',
      value: 'backend',
      default: false
    },
    {
      title: 'Mobile App',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons1.svg',
      price: '$20/hour',
      value: 'mobile',
      default: true
    },
    {
      title: 'UI/UX',
      titlesub: 'Design',
      icons: 'assets/images/icons/icons3.svg',
      price: '$12/hour',
      value: 'uiux',
      default: false
    },
    {
      title: 'Front-End',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons5.svg',
      price: '$10/hour',
      value: 'frontend',
      default: false
    },
    {
      title: 'Backend System',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons8.svg',
      price: '$18/hour',
      value: 'wordpress',
      default: false
    },
    {
      title: 'E-Commerce',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons6.svg',
      price: '$8/hour',
      value: 'maintenance',
      default: false
    },
    {
      title: 'Product',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons9.svg',
      price: '$18/hour',
      value: 'backend',
      default: false
    },
    {
      title: 'Mobile App',
      titlesub: 'Development',
      icons: 'assets/images/icons/icons1.svg',
      price: '$20/hour',
      value: 'mobile',
      default: true
    },
    {
      title: 'UI/UX',
      titlesub: 'Design',
      icons: 'assets/images/icons/icons3.svg',
      price: '$12/hour',
      value: 'uiux',
      default: false
    }
    ];

    $scope.emailValidation = function (emailAddress) {
      var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      return pattern.test(emailAddress);
    };

    $scope.submitSubscribe = function(event){
      console.log($scope.subscribe.email);
      if( ($scope.subscribe.email == "" || $scope.subscribe.email == undefined))
      {
        // $scope.subscribe.required = 'has-error';
        $scope.subscribe.success = 'invalid';
        angular.element('#gift-form input.email').attr('placeholder', 'Email is Empty');
      }else if($scope.emailValidation( $scope.subscribe.email ) != true)
      {
        // $scope.subscribe.required = 'has-error';
        $scope.subscribe.success = 'invalid';
        angular.element('#gift-form input.email').val('').attr('placeholder', 'Email is not valid');
      }else
      {
          $scope.subscribe.required = '';
          $scope.subscribe.success = 'progress';
          kodekuService.subscribe({
              email : $scope.subscribe.email
          }).then(function(data){
              if(data.status)
              {
                  $scope.subscribe.success = 'success';
                  angular.element('#gift-form input.email').val('').attr('placeholder', 'Success! Please check your email');
                  $timeout(function(){
                    $('.popup-gift').find('a.close').click();
                  }, 1000);
              }
          });
      }
      event.preventDefault();
      event.stopPropagation();
  };

    angular.element(document).ready(function () {
        $('.our-works-list').slick({
          infinite: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: true,
          arrows: false,
        });

        $timeout(function(){
          angular.element('.img-hero').addClass('inslide');
        },7000);

        $(document).on('click','.close[logoload-close]',function(e){
        e.preventDefault();
        var dialog = $(this).parents('.dialog-wrap');
        dialog.find('.dialog-overlay').addClass('inload');
        dialog.find('.dialog-box.home').addClass('invert');
        setTimeout(function(){ dialog.find('.dialog-overflow').addClass('hideleftright'); }, 200);
        setTimeout(function(){
          dialog.addClass('dialog-close').removeClass('dialog-open');
          $('body').removeClass('dialog-open');
          $('.animated-head').addClass('inview');
          //$('.cookies_popup').addClass('animated');
          setInterval(function () {
            $("#service-text-trigger").click();
          }, 5000);
        }, 1500);
        setTimeout(function(){
          $('.hero-title').addClass('hiddenoverflow');
        }, 4200);
        setTimeout(function(){ $('.cookies_popup').addClass('addopacity'); }, 4500);
        setTimeout(function(){ $('[cookiesclose]').trigger('click'); }, 11500);
      });
    });


  }])

  .directive('circleChooser', function ($timeout, $interval) {
    return {
      restrict: 'E',
      templateUrl: 'layout/home/circle.template.html',
      scope: {
        lists: "=",
      },
      link: function (scope, elm, attrs) {
        scope.isActive = 0;
        var parents = elm[0].children[0].children[0],
          elems = parents.children;

        var nbOptions = 8,
          angleStart = -360;
        var auto = "";

        scope.rotate = function (li, d) {
          $({
            d: angleStart
          }).animate({
            d: d
          }, {
            step: function (now) {
              $(li).css({
                transform: 'rotate(' + now + 'deg)'
              });
            },
            duration: 0
          });
        }

        scope.setRoundHeight = function () {
          var roundSelector = $(".roundup");
          roundSelector.each(function () {
            var roundHeight = $(this).width();
            $(this).css("height", roundHeight + "px");
          });
        }

        scope.toggleOptions = function (s) {
          $(s).toggleClass('open');
          var li = $(s).find('li');
          var deg = 360 / li.length;
          for (var i = 0; i < li.length; i++) {
            var d = i * deg;
            $(s) ? scope.rotate(li[i], d) : scope.rotate(li[i], angleStart);
          }
        }

        scope.switchContent = function () {
          var obj = $(".roundup li a");
          obj.on("click", function (e) {
            var activeClass = "active",
              numItems = $(".roundup li a").length,
              rotationIncrement = 360 / numItems,
              clickedIndex = $(this).parent().index(),
              previousActiveIndex = $(".roundup li.active a").parent().index(),
              previousRotation = previousActiveIndex * rotationIncrement,
              newRotation = clickedIndex * rotationIncrement,
              distance = newRotation - previousRotation,

              imgactive = $('.roundup li a.active').find('img').data("active"),
              img = $('.roundup li a.active').find('img').attr('src'),
              active = $(this).find('img').data('active');

            $(".roundup").css({
              transform: 'rotateZ(-' + (clickedIndex * rotationIncrement) + 'deg)'
            });

            e.preventDefault();

            if ($(this).hasClass('active')) {
              return false;
            }

            obj.removeClass(activeClass);
            $(this).toggleClass(activeClass);

            console.log(clickedIndex);

            $timeout(function () {
              scope.isActive = clickedIndex;
            });

            $('.item-slider').children().css('opacity', '0');

            // scope.$apply();

          });
        }

        $timeout(function () {
          scope.setRoundHeight();
          scope.toggleOptions('.roundup');
          scope.switchContent();
        }, 200);

      },
      controller: function () {

      }
    }
  })

  .directive('heroRocket', function () {
    return {
      restrict: 'E',
      templateUrl: './layout/home/hero.rocket.html',
      link: function (scope, elm, attrs) {
        var controllerAgency = new ScrollMagic.Controller();

        var plane_bottom = 8,
          plane_left = 8,
          plane2_bottom = 8,
          plane_lstart = 32,
          plane_bstart = 16;

        //var kodekuOffset = ($('.section-kodeku').offset().top === undefined ? 0 : $('.section-kodeku').offset().top);

        var heroreversePlane, planeStartOver;

        var lastscrolltop = 0;
        // $(window).load(function(){
        setTimeout(function () {
          var heroPlane = TweenMax.fromTo('.img-hero.agencypages', 2,
            {
              css:
                { bottom: '-' + plane_bstart + 'em', left: '-' + plane_lstart + 'em' }
            },
            {
              css:
                { bottom: '-'+(plane_bstart/4)+'em', left: '-'+(plane_lstart/8)+'em' }, ease: Expo.easeOutQuart, onComplete: function () {
                  setTimeout(() => {
                    $('.img-hero.agencypages .floating').addClass('floating-animation');
                    $('.after-rocket').addClass('inview');
                  }, 1000);
                }
            });
        }, 6500);
        // });

        var plane = TweenMax.fromTo('.img-hero.agencypages .psw2', 1000,
            {delay:150, transform:'translate3d(0em, 0em, 0em)', ease:Expo.easeOutQuart},
            {delay:150, transform:'translate3d(50em, -40em, 0em)', ease:Expo.easeOutQuart, onStart: function(){
              if ($('.section-kodeku').length){
                $('body,html').animate({
                  scrollTop: $('.section-kodeku').offset().top
                }, 1000);
              }
            }}
        );

        var booster = TweenMax.fromTo('.img-hero.agencypages .psw1', 1500, 
            { delay:150, transform:'translate3d(0em, 0em, 0em)'},
            { delay:150, transform:'translate3d(-60em, 40em, 0em)'}
        );

        var planeStart = new ScrollMagic.Scene({ duration: 1000 }).setTween(plane).addTo(controllerAgency);
        var bootsterStart = new ScrollMagic.Scene({ duration: 1000 }).setTween(booster).addTo(controllerAgency);
          // .on("leave", function (e) {
          //   if (e.progress == '1') {
          //     controllerAgency.removeScene([planeStart, bootsterStart]);
          //     planeStart.removeTween(true);
          //     bootsterStart.removeTween(true);

          //     heroreversePlane = TweenMax.fromTo('.img-hero.agencypages', 2,
          //       {
          //         css:
          //           { bottom: '-' + (plane_start - 1000) + 'em', left: '-' + (plane_start - 1000) + 'em' }
          //       },
          //       {
          //         css:
          //           { bottom: '-' + plane_start + 'em', left: '-' + plane_start + 'em' }, ease: Expo.easeOutQuart, onComplete: function () {
          //             $('.img-hero.agencypages .floating').addClass('floating-animation');
          //           }
          //       });
          //     planeStartOver = new ScrollMagic.Scene({ duration: 100 }).setTween(heroreversePlane).addTo(controllerAgency);
          //   }
          //});
        var startpin = new ScrollMagic.Scene({ duration: 600 }).setPin('.pinned').addTo(controllerAgency);

        // remove floating effect

        var scrollFn = function () {
          var scrollTop = $(this).scrollTop();
          var section2 = $('.section-kodeku').offset().top;
          if (scrollTop > 0) {
            $('.img-hero.agencypages .floating').removeClass('floating-animation');
          } else {
            $('.img-hero.agencypages .floating').addClass('floating-animation');
            $('.rocket-list[data-rocket="1"]').removeClass('hide');
            $('.rocket-list[data-rocket="2"]').addClass('hide');
            $('.rocket-list[data-rocket="3"]').addClass('hide');
          }

          // if (scrollTop >= 100) {
          //   $('.rocket-list[data-rocket="1"]').addClass('hide');
          //   $('.rocket-list[data-rocket="2"]').removeClass('hide');
          // } else {
          //   $('.rocket-list[data-rocket="1"]').removeClass('hide');
          //   $('.rocket-list[data-rocket="2"]').addClass('hide');
          // }

          // if (scrollTop >= 200) {
          //   $('.rocket-list[data-rocket="2"]').addClass('hide');
          //   $('.rocket-list[data-rocket="3"]').removeClass('hide');
          // } else {
          //   // $('.rocket-list[data-rocket="2"]').removeClass('hide');
          //   $('.rocket-list[data-rocket="3"]').addClass('hide');
          // }

          // if (scrollTop >= 300 && lastscrolltop <= 300) {
          //   $("html, body").stop().animate({ scrollTop: section2 }, 500, 'swing');
          // } else if (scrollTop <= (section2 - 100) && lastscrolltop >= (section2 - 100)) {
          //   $("html, body").stop().animate({ scrollTop: 100 }, 500, 'swing');
          // }
          
          // if (scrollTop == 0) {
          //   controllerAgency.removeScene(planeStartOver);
          //   planeStartOver.removeTween(true);

          //   var planeStart = new ScrollMagic.Scene({ duration: 2500 }).setTween(plane).addTo(controllerAgency);
          //   var bootsterStart = new ScrollMagic.Scene({ duration: 900 })
          //     .setTween(booster)
          //     .addTo(controllerAgency)
          //     .on("leave", function (e) {
          //       if (e.progress == '1') {
          //         controllerAgency.removeScene([planeStart, bootsterStart]);
          //         planeStart.removeTween(true);
          //         bootsterStart.removeTween(true);

          //         heroreversePlane = TweenMax.fromTo('.img-hero.agencypages', 2,
          //           {
          //             css:
          //               { bottom: '-' + (plane_start - 1000) + 'em', left: '-' + (plane_start - 1000) + 'em' }
          //           },
          //           {
          //             css:
          //               { bottom: '-' + plane_start + 'em', left: '-' + plane_start + 'em' }, ease: Expo.easeOutQuart, onComplete: function () {
          //                 $('.img-hero.agencypages .floating').addClass('floating-animation');
          //               }
          //           });

          //         planeStartOver = new ScrollMagic.Scene({ duration: 900 }).setTween(heroreversePlane).addTo(controllerAgency);
          //       }
          //     });
          // }
          // lastscrolltop = scrollTop;
        }

        $(window).scroll(scrollFn);
        scope.$on(
          "$destroy",
          function handleDestroyEvent() {
            $(window).off('scroll', scrollFn);
          }
        );
      }
    }
  })