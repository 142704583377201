'use strict';

angular.module('myApp.sitemap', ['ngRoute'])

.controller('SitemapsCtrl', ['$scope', '$timeout', function($scope, $timeout) {
	$scope.dataLogo = 'assets/images/logo-black.png';

	$scope.pagination = {
        onepage : [
            {
                classes_targer: 'section-1',
                active_state: 'active'
            },
            {
                classes_targer: 'section-footer',
            },
        ]
    };
}]);